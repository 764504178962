import React from 'react';
import {motion} from 'framer-motion';
import dayjs from 'dayjs';
import {graphql} from 'gatsby';
import {PageHeader} from '../components/Headings';
import Card from '../components/Card';
import Layout from '../components/Layout';
import {pageVariants, pageTransition} from '../components/Transitions';

const sortByPostDateDesc = (a, b) => dayjs(b.postDate) - dayjs(a.postDate);

function OurWork({data}) {
  const {
    craftOurWorkOurWorkEntry: {title: pageHeading, subHeading: pageSubheading, seomatic},
    allCraftCaseStudiesCaseStudyEntry: {nodes: caseStudies},
  } = data;

  return (
    <Layout hasContact seomatic={seomatic}>
      <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
        <PageHeader heading={pageHeading} subheading={pageSubheading} />
        <div className="bg-white">
          <div className="max-w-screen-xl m-auto w-full pt-10 pb-20 px-6 text-black grid gap-y-10 md:pt-16 lg:grid-cols-2 lg:pb-40 lg:gap-x-12 lg:gap-y-40 xl:gap-x-24 xl:pl-0 xl:pr-0">
            {caseStudies.sort(sortByPostDateDesc).map(({id, title, description, clientName, uri, tags, heroImage: [heroImage]}) => (
              <Card key={id} heading={title} client={clientName} blurb={description} path={uri} heroImage={heroImage} tags={tags} />
            ))}
          </div>
        </div>
      </motion.div>
    </Layout>
  );
}

export const query = graphql`
  query CaseStudiesQuery {
    allCraftCaseStudiesCaseStudyEntry {
      nodes {
        id
        title
        description
        clientName
        slug
        uri
        postDate
        heroImage {
          ... on Craft_assets_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, height: 600, quality: 80)
              }
            }
          }
        }
        tags {
          id
          title
        }
      }
    }
    craftOurWorkOurWorkEntry {
      seomatic {
        ...SeomaticFields
      }
      title
      subHeading
    }
  }
`;

export default OurWork;
